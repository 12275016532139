<template lang="pug">
  #packing-up-checklist.step-component
    h2 Packing Up

    .checklist-item(v-for="(instruction, index) in instructions" :key="index")
      icon(:data="icon(instruction.icon)")
      span {{ instruction.text }}

    button.button(@click="gotoNext")
      icon(data="@icon/check.svg")
      span Completed
</template>

<script>
// **** Sample config ****
// {
//   component: 'PackingUpChecklist',
//   config: {
//     instructions: [
//       {
//         icon: 'box-checklist-bp.svg',
//         text: 'Blood Pressure Monitor back in Box 5',
//       },
//       {
//         icon: 'box-checklist-centrifuge.svg',
//         text: 'Centrifuge back in Box 4',
//       },
//       {
//         icon: 'box-checklist-saliva.svg',
//         text: 'Saliva tube in tamper proof bag #1 back in Box 3',
//       },
//       {
//         icon: 'box-checklist-weight.svg',
//         text: 'Weight Scale back in Box 2',
//       },
//       {
//         icon: 'box-checklist-blood.svg',
//         text:
//           'Blood sample tube in tamper proof cylinder inside tamper proof bag #2 back in Box 1',
//       },
//     ],
//   },
// },
export default {
  props: {
    instructions: { type: Array, required: true },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
    icon,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}

function icon(icon) {
  return require(`@icon/${icon}`)
}
</script>
